// External Imports
import { useInput } from "react-admin";

const JsonInput = ({
  source,
  label
}: any) => {
  const { id, field, fieldState } = useInput({ source });
  const { value, ...props } = field;

  return (
    <label htmlFor={id}>
      {label}
      <textarea
        style={{ width: 400 }}
        id={id}
        value={value ? JSON.stringify(value, null, "\t") : ""}
        {...props}
      />
      {fieldState.error && <span>{fieldState.error.message}</span>}
    </label>
  );
};

export default JsonInput;
