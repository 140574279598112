export const validatePasswordsMatch =
  (
    field: any,
    message = {
      message: "ra.validation.do_not_match",
      args: { _: "Passwords do not match" },
    }
  ) =>
  (value: any, allValues: any) => {
    if (value !== allValues[field]) {
      return message;
    }
  };

export const cleanEmail = (email: any) => email.trim().toLowerCase();
