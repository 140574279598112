// External Imports
import { RichTextInput } from "ra-input-rich-text";
import {
  BooleanInput,
  TextInput,
  PasswordInput,
  NumberInput,
  ArrayInput,
  DateInput,
  TimeInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  email,
} from "react-admin";

// Internal Imports
import JsonInput from "./JsonInput";

// --- Types ---
// ARRAY
// BOOLEAN
// DATE
// DATETIME
// DOCUMENT
// IMAGE
// NUMBER
// REFERENCE
// MANY_REFERENCE
// RICH_TEXT
// SELECT
// MULTI_SELECT
// TEXT
// TIME
// EMAIL
// URL
// SECRET
// JSON
// PHONE_NUMBER

export const getDynamicInput = (field: any) => {
  if (field.type === undefined) {
    console.error("Field type is undefined");
  }

  switch (field.type) {
    case "BOOLEAN":
      return <BooleanInput key={field.source} label={field.label} source={field.source} />;

    case "TEXT":
      return <TextInput key={field.source} label={field.label} source={field.source} resettable={true} />;

    case "URL":
      return (
        <TextInput
          key={field.source}
          label={field.label}
          source={field.source}
          resettable={true}
          type="url"
        />
      );

    case "EMAIL":
      return (
        <TextInput
          key={field.source}
          label={field.label}
          source={field.source}
          resettable={true}
          type="email"
          validate={email("Please enter a valid email address")}
        />
      );

    case "PHONE_NUMBER":
      return (
        <TextInput
          key={field.source}
          label={field.label}
          source={field.source}
          resettable={true}
          type="tel"
        />
      );

    case "JSON":
      return <JsonInput key={field.source} label={field.label} source={field.source} />;

    case "RICH_TEXT":
      return <RichTextInput key={field.source} label={field.label} source={field.source} />;

    case "SECRET":
      return (
        <PasswordInput key={field.label} label={field.label} source={field.source} initiallyVisible={false} />
      );

    case "NUMBER":
      return <NumberInput key={field.source} label={field.label} source={field.source} />;

    case "ARRAY":
      // @ts-expect-error TS(2741): Property 'children' is missing in type '{ key: any... Remove this comment to see the full error message
      return <ArrayInput key={field.source} label={field.label} source={field.source} />;

    case "DATE":
      return <DateInput key={field.source} label={field.label} source={field.source} />;

    case "TIME":
      return <TimeInput key={field.source} label={field.label} source={field.source} />;

    case "DATETIME":
      return <DateTimeInput key={field.source} label={field.label} source={field.source} />;

    case "IMAGE":
      return (
        <ImageInput key={field.source} label={field.label} source={field.source}>
          <ImageField source="src" title="title" />
        </ImageInput>
      );

    case "DOCUMENT":
      return (
        <FileInput key={field.source} label={field.label} source={field.source}>
          <FileField source="src" title="title" />
        </FileInput>
      );

    case "SELECT":
      return (
        <SelectInput key={field.source} label={field.label} source={field.source} choices={field.options} />
      );

    case "MULTI_SELECT":
      return (
        <SelectArrayInput
          key={field.source}
          label={field.label}
          source={field.source}
          choices={field.options}
        />
      );

    case "REFERENCE":
      return (
        <ReferenceInput
          key={field.source}
          label={field.label}
          source={field.source}
          reference={field.reference}
        />
      );

    case "MANY_REFERENCE":
      // @ts-expect-error TS(2741): Property 'reference' is missing in type '{ key: an... Remove this comment to see the full error message
      return <ReferenceArrayInput key={field.source} label={field.label} source={field.source} />;

    default:
      return <TextInput key={field.source} label={field.label} source={field.source} />;
  }
};

// export default DynamicInput;
