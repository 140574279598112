// External Imports
import { useState, useEffect } from "react";
import { Admin, Resource, Layout } from "react-admin";

// Internal Imports
import { AdminAuthProvider } from "auth/authProvider";
import { AdminDataProvider } from "data/dataProvider";
import { lightTheme } from "theme";
import { schema } from "./schema";
import client from "data/client";

// Pages
import Dashboard from "pages/Dashboard";
import NotFound from "pages/NotFound";
import Loading from "pages/Loading";
import Login from "pages/Login";

// Components
import DynamicCreate from "components/DynamicCreate";
import DynamicList from "components/DynamicList";
import DynamicEdit from "components/DynamicEdit";
import DynamicShow from "components/DynamicShow";
import Notification from "components/Notification";
import Header from "components/Header";

const authProvider = AdminAuthProvider({
  userPoolId: process.env.REACT_APP_ADMIN_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_ADMIN_COGNITO_CLIENT_ID,
  region: process.env.REACT_APP_ADMIN_COGNITO_REGION,
});

const dataProvider = AdminDataProvider({
  apiVersion: process.env.REACT_APP_ADMIN_API_VERSION,
  apiUrl: process.env.REACT_APP_ADMIN_API_URL,
});

const PanelLayout = (props: any) => <Layout {...props} appBar={Header} />;

const App = () => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    client
      .get(`/permissions`)
      .then(({ data }) => setPermissions(data || []))
      .catch((err) => console.error("Failed to load permissions", err));
  }, []);

  return (
    <Admin
      // store={memoryStore()}
      // @ts-expect-error TS(2322): Type '{ login({ email, password, newPassword }: an... Remove this comment to see the full error message
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      catchAll={NotFound}
      loginPage={Login}
      ready={Loading}
      notification={Notification}
      layout={PanelLayout}
      theme={lightTheme}
      title="Brelly Admin"
      disableTelemetry
      requireAuth
    >
      {schema.map((resource) => {
        // @ts-expect-error TS(2339): Property 'isSuperuser' does not exist on type '{}'... Remove this comment to see the full error message
        if (permissions.isSuperuser || permissions[resource.name]) {
          return (
            <Resource
              key={resource.path}
              options={{ label: resource.title }}
              name={resource.path}
              icon={resource.icon}
              // hasCreate={permissions.isSuperuser || permissions[resource.name].WRITE}
              // hasEdit={permissions.isSuperuser || permissions[resource.name].WRITE}
              // hasShow={permissions.isSuperuser || permissions[resource.name].READ}
              create={<DynamicCreate schema={resource.createSchema} />}
              list={<DynamicList schema={resource.listSchema} />}
              edit={<DynamicEdit schema={resource.editSchema} />}
              show={<DynamicShow schema={resource.showSchema} />}
            />
          );
        } else {
          return null;
        }
      })}
    </Admin>
  );
};

export default App;
