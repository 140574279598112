// External Imports
import { HttpError } from "react-admin";
import { Amplify, Auth } from "aws-amplify";

// Internal Imports
import { ErrorRequireNewPassword } from "./errors";
import { cleanEmail } from "./utils";
import client from "data/client";
import "crypto-js/lib-typedarrays";

export const AdminAuthProvider = ({
  userPoolId,
  clientId,
  region
}: any) => {
  if (!userPoolId) {
    throw new Error("Missing or invalid user pool id");
  }

  if (!clientId) {
    throw new Error("Missing or invalid client id");
  }

  Amplify.configure({
    Auth: {
      region: region,
      userPoolId: userPoolId,
      userPoolWebClientId: clientId,
      authenticationFlowType: "USER_SRP_AUTH",
    },
  });

  return {
    async login({
      email,
      password,
      newPassword
    }: any) {
      return new Promise(async (resolve, reject) => {
        try {
          const _email = cleanEmail(email);
          var user = await Auth.signIn(_email, password);
          console.log("Auth.signIn", user);

          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            if (newPassword) {
              user = await Auth.completeNewPassword(user, newPassword);
              console.log("Auth.completeNewPassword", user);
            } else {
              // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
              reject(new ErrorRequireNewPassword());
            }
          }

          console.log("user", user);

          client
            .post("login")
            .then(({ data }) => resolve(data))
            .catch(reject);
        } catch (err) {
          reject(err);
        }
      });
    },

    async logout() {
      return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser()
          .then(() => Auth.signOut())
          // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          .then(() => resolve())
          // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          .catch(() => resolve());
      });
    },

    // called when the API returns an error
    async checkError({
      status
    }: any) {
      if (status === 401) {
        throw new Error("Unauthenticated");
      }

      if (status === 403) {
        throw new Error("Unauthorized");
      }
    },

    // called when the user navigates to a new location, to check for authentication
    async checkAuth() {
      return new Promise((resolve, reject) => {
        Auth.currentSession()
          // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          .then(() => resolve())
          .catch(() => reject(new HttpError("Session expired", 401)));
      });
    },

    // called when the user navigates to a new location, to check for permissions / roles
    async getPermissions() {
      return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser()
          .then(() => {
            client
              .get("permissions")
              .then(({ data }) => resolve(data))
              .catch((err) => {
                console.error("Failed to fetch permissions", err);
                reject(err);
              });
          })
          .catch(reject);
      });
    },

    async getIdentity() {
      return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser()
          .then(() => {
            client
              .get("admin-users")
              .then(({ data }) => data)
              .catch(reject);
          })
          .catch(() => reject(new HttpError("No user", 401)));
      });
    },

    async handleCallback() {
      //   const urlParams = new URLSearchParams(window.location.hash.slice(1));
      //   const error = urlParams.get("error");
      //   const errorDescription = urlParams.get("error_description");
      //   const idToken = urlParams.get("id_token");
      //   const accessToken = urlParams.get("access_token");
      //   if (error) {
      //     throw new Error(errorDescription);
      //   }
      //   if (idToken == null || accessToken == null) {
      //     throw new Error("Failed to handle login callback.");
      //   }
      //   //   return Auth.currentAuthenticatedUser()
      //   //     .then((user) => user)
      //   //     .catch(() => null);
      //   const session = new CognitoUserSession({
      //     IdToken: new CognitoIdToken({ IdToken: idToken }),
      //     RefreshToken: new CognitoRefreshToken({
      //       RefreshToken: null,
      //     }),
      //     AccessToken: new CognitoAccessToken({
      //       AccessToken: accessToken,
      //     }),
      //   });
      //   const user = new CognitoUser({
      //     Username: session.getIdToken().decodePayload()["cognito:username"],
      //     Pool: userPool,
      //     Storage: window.localStorage,
      //   });
      //   user.setSignInUserSession(session);
      //   return user;
    },
  };
};
