// External Imports
import { ToggleThemeButton, AppBar } from "react-admin";
import { Typography } from "@mui/material";

// Internal Imports
import { lightTheme, darkTheme } from "theme";

const Header = (props: any) => (
  <AppBar {...props}>
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
    {/* @ts-expect-error TS(2322): Type '{ palette: { mode: string; primary: { main: ... Remove this comment to see the full error message */}
    <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
  </AppBar>
);

export default Header;
