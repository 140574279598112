// External Imports
import { Form, LoginFormClasses, required, TextInput, useNotify, useTranslate } from "react-admin";
import { Button, CardContent, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";

// Internal Imports
import { validatePasswordsMatch } from "auth/utils";
import { useLoginAuth } from "auth/useLoginAuth";

const LoginForm = ({
  redirectTo,
  className
}: any) => {
  // @ts-expect-error TS(2339): Property 'isLoading' does not exist on type '((val... Remove this comment to see the full error message
  const [login, { isLoading, requireNewPassword }] = useLoginAuth({ redirectTo });
  const translate = useTranslate();
  const notify = useNotify();

  const submit = (values: any) => {
    if (requireNewPassword) {
      if (!values.newPassword) {
        return notify("ra.auth.sign_in_error", {
          type: "error",
          messageArgs: {
            _: "Please enter a new password",
          },
        });
      }

      if (!values.confirmNewPassword) {
        return notify("ra.auth.sign_in_error", {
          type: "error",
          messageArgs: {
            _: "Please confirm your password",
          },
        });
      }
    } else {
      if (!values.email) {
        return notify("ra.auth.sign_in_error", {
          type: "error",
          messageArgs: {
            _: "Please enter your email",
          },
        });
      }

      if (!values.password) {
        return notify("ra.auth.sign_in_error", {
          type: "error",
          messageArgs: {
            _: "Please enter your password",
          },
        });
      }
    }

    // @ts-expect-error TS(2349): This expression is not callable.
    login(values).catch((err: any) => {
      notify(
        typeof err === "string"
          ? err
          : typeof err === "undefined" || !err.message
          ? "ra.auth.sign_in_error"
          : err.message,
        {
          type: "error",
          messageArgs: {
            _: typeof err === "string" ? err : err && err.message ? err.message : undefined,
          },
        }
      );
    });
  };

  if (requireNewPassword) {
    return (
      <Form onSubmit={submit} mode="onChange" noValidate className={clsx("RaLoginForm-root", className)}>
        <CardContent className={LoginFormClasses.content}>
          <Typography>
            {translate("ra.auth.require_new_password", {
              _: "Please enter a new password",
            })}
          </Typography>

          <TextInput
            source="newPassword"
            label={translate("ra.auth.password")}
            type="password"
            validate={required()}
            fullWidth
          />

          <TextInput
            source="confirmNewPassword"
            label={translate("ra.auth.confirm_password", {
              _: "Confirm password",
            })}
            type="password"
            validate={[required(), validatePasswordsMatch("newPassword")]}
            fullWidth
          />

          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
            fullWidth
            className={LoginFormClasses.button}
          >
            {isLoading ? (
              <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
            ) : (
              translate("ra.auth.sign_in")
            )}
          </Button>
        </CardContent>
      </Form>
    );
  }

  return (
    <Form onSubmit={submit} mode="onChange" noValidate>
      {/* className={clsx("RaLoginForm-root", className)}> */}
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          source="email"
          label={translate("ra.auth.username")}
          validate={required()}
          fullWidth
        />

        <TextInput
          source="password"
          label={translate("ra.auth.password")}
          type="password"
          autoComplete="current-password"
          validate={required()}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          // color="primary" // rgb(70, 198, 149)
          sx={{ background: "rgb(70, 198, 149)" }}
          disabled={isLoading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {isLoading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate("ra.auth.sign_in")
          )}
        </Button>
      </CardContent>
    </Form>
  );
};

const Login = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
      }}
    >
      <img
        alt="brelly-login"
        src="/assets/imgs/brellyUrban.jpg"
        style={{ padding: 20, height: "100vh", width: "50vw", objectFit: "cover" }}
      />
      {/* <div style={{ height: "100vh", width: "50vw" }}>
      </div> */}

      <div
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          width: "50vw",
        }}
      >
        <img
          alt="brelly-login"
          src="/assets/imgs/brellyLogo.png"
          style={{ margin: 20, width: "40%", objectFit: "contain" }}
        />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
