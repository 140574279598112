// External Import
import AccountCircle from "@mui/icons-material/AccountCircle";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// email: STRING
// firstName: STRING
// middleName: STRING
// lastName: STRING
// phoneNumber: STRING
// stripeId: STRING
// acceptedTncAt: DATE
// acceptedTncVersion: STRING

// photoId: UUID
// ownerId -> files
// ownerId -> reports
// ownerId -> documents
// ownerId -> assets
// ownerId -> claims
// userId -> claimTasks
// userId -> payees
// userId -> tags
// userId -> canopyConnectPulls
// userId -> proRequests

export const users = {
  title: "Users",
  icon: AccountCircle, // Person
  path: "users",
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "First Name", source: "firstName", type: dataTypes.TEXT },
    { label: "Last Name", source: "lastName", type: dataTypes.TEXT },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Email", source: "email", type: dataTypes.TEXT },
    { label: "First Name", source: "firstName", type: dataTypes.TEXT },
    { label: "Middle Name", source: "middleName", type: dataTypes.TEXT },
    { label: "Last Name", source: "lastName", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Accepted TnC At", source: "acceptedTncAt", type: dataTypes.DATETIME },
    { label: "Accepted TnC Version", source: "acceptedTncVersion", type: dataTypes.TEXT },
    { label: "Profile Picture", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
    {
      label: "Assets",
      reference: "assets",
      target: "ownerId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Claims",
      reference: "claims",
      target: "ownerId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Files",
      reference: "files",
      target: "ownerId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Reports",
      reference: "reports",
      target: "ownerId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Documents",
      reference: "documents",
      target: "ownerId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Claim Tasks",
      reference: "claim-tasks",
      target: "userId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Payees",
      reference: "payees",
      target: "userId",
      type: dataTypes.MANY_REFERENCE,
    },
    // {
    //   label: "Tags",
    //   reference: "tags",
    //   target: "userId",
    //   type: dataTypes.MANY_REFERENCE,
    // },
    {
      label: "Canopy Connect Pulls",
      reference: "canopy-connect-pulls",
      target: "userId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Pro Requests",
      reference: "pro-requests",
      target: "userId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "First Name", source: "firstName", type: dataTypes.TEXT },
    { label: "Middle Name", source: "middleName", type: dataTypes.TEXT },
    { label: "Last Name", source: "lastName", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Accepted TnC At", source: "acceptedTncAt", type: dataTypes.DATETIME },
    { label: "Accepted TnC Version", source: "acceptedTncVersion", type: dataTypes.TEXT },
    { label: "Profile Picture", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
  editSchema: [
    { label: "First Name", source: "firstName", type: dataTypes.TEXT },
    { label: "Middle Name", source: "middleName", type: dataTypes.TEXT },
    { label: "Last Name", source: "lastName", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Accepted TnC At", source: "acceptedTncAt", type: dataTypes.DATETIME },
    { label: "Accepted TnC Version", source: "acceptedTncVersion", type: dataTypes.TEXT },
    { label: "Profile Picture", source: "photoId", reference: "photos", type: dataTypes.REFERENCE },
  ],
};
