// External Import
import BeachAccess from "@mui/icons-material/BeachAccess";

// Internal Imports
import { dataTypes } from "constants/dataTypes";

// id: UUID
// name: STRING
// branding: JSONB
// supportedByCanopy: BOOLEAN
// canopyData: JSONB
// website: TEXT
// ranking: INTEGER
// phoneNumber: STRING

// insuranceCompanyId -> policies
// insuranceCompanyId -> professionals

export const insuranceCompanies = {
  title: "Insurance Companies",
  icon: BeachAccess,
  path: "insurance-companies",
  recordRepresentation: (record: any) => `User ${record.userId}`,
  listSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Supported By Canopy", source: "supportedByCanopy", type: dataTypes.BOOLEAN },
  ],
  showSchema: [
    { label: "ID", source: "id", type: dataTypes.TEXT },
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Ranking", source: "ranking", type: dataTypes.NUMBER },
    { label: "Website", source: "website", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Supported By Canopy", source: "supportedByCanopy", type: dataTypes.BOOLEAN },
    { label: "Canopy Data", source: "canopyData", type: dataTypes.JSON },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
    {
      label: "Policies",
      reference: "policies",
      target: "insuranceCompanyId",
      type: dataTypes.MANY_REFERENCE,
    },
    {
      label: "Professionals",
      reference: "professionals",
      target: "insuranceCompanyId",
      type: dataTypes.MANY_REFERENCE,
    },
    { label: "Updated At", source: "updatedAt", type: dataTypes.DATETIME },
    { label: "Created At", source: "createdAt", type: dataTypes.DATETIME },
    { label: "Deleted At", source: "deletedAt", type: dataTypes.DATETIME },
  ],
  createSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Ranking", source: "ranking", type: dataTypes.NUMBER },
    { label: "Website", source: "website", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Supported By Canopy", source: "supportedByCanopy", type: dataTypes.BOOLEAN },
    { label: "Canopy Data", source: "canopyData", type: dataTypes.JSON },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
  ],
  editSchema: [
    { label: "Name", source: "name", type: dataTypes.TEXT },
    { label: "Ranking", source: "ranking", type: dataTypes.NUMBER },
    { label: "Website", source: "website", type: dataTypes.TEXT },
    { label: "Phone Number", source: "phoneNumber", type: dataTypes.TEXT },
    { label: "Supported By Canopy", source: "supportedByCanopy", type: dataTypes.BOOLEAN },
    { label: "Canopy Data", source: "canopyData", type: dataTypes.JSON },
    { label: "Branding", source: "branding", type: dataTypes.JSON },
  ],
};
